
body {
  font-family: Gilroy, sans-serif;
  margin: 0;
}

.blizz-customer-dashboard {
  height: 100vh;
  overflow: scroll;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}

img, video {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d256e0e09a46375923c_Radomir%20Tinkov%20-%20Gilroy-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d25b27a562aa2904d49_Radomir%20Tinkov%20-%20Gilroy-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d25a5916f045c9d709c_Radomir%20Tinkov%20-%20Gilroy-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d256e0e090c6f75923d_Radomir%20Tinkov%20-%20Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d255617874c51273d41_Radomir%20Tinkov%20-%20Gilroy-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d26cf49e7979fccf0be_Radomir%20Tinkov%20-%20Gilroy-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d26f78e032cbac95be4_Radomir%20Tinkov%20-%20Gilroy-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('https://uploads-ssl.webflow.com/62deeed10091d6c4e7a0953c/636a7d26fd2b2cd723800dfd_Radomir%20Tinkov%20-%20Gilroy-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


.skeleton {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient( to right, rgb(255 255 255 / 0%), rgb(148 185 223 / 50%) 50%, rgb(255 255 255) 200% ), transparent;
  background-repeat: repeat-y;
  background-size: 80% 100%;
  background-position: 0 0;
  animation: shine 1s ease-in-out infinite;
}

@keyframes shine {

  0% {
    background-position:0% 0, 0 0;
  }
  50% {
    background-position: 220% 0, 0 0;
  }
  100% {
    background-position: 0% 0, 0 0;
  }
}

.view {
  width: 100%;
}

.sidebar-visible{
  left: 0px !important;
}

.sidebar{
  transition: .2s ease-in-out;
  position: fixed;
  left: -1000px;
}

@media screen and (min-width: 1024px){
  .sidebar{
    left: 0px;
    width: 350px;
  }

  .view {
    width: calc(100% - 350px);
  }

}

.box-shadow{
  box-shadow: 5px 4px 20px 8px #e2e8f0cc ;
}
.avatar{
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.addresses p{
  margin: 0;
}

.reward-bar{
  height: 20px;
}

.reward-amount{
  transition: .3s ease-in-out;
}

.stat-metric{
  font-size: 1.5vw;
}


.animated-container {
 margin: 0 auto;
 justify-content: center;

}

.add-money .animated-container::before {
  content: "$";
  display: flex;
  align-items: center;
  padding-right: 2px;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #0047ba;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
